<template>
  <div class="page">
    <Navbar isBack="true" title="VIP礼包" />
    <div class="page_box">
      <div class="vip_level_nav">VIP礼包</div>
      <div class="vip_level_dec">
        礼包权益玩家每月仅可领取一次，不同VIP等级的玩家可以领取不同的VIP礼包，该礼包通过手动领取的方式，领取之后会以以下其中一种形式进行发放：
      </div>
      <div class="vip_level_dec">
        1：自动发放到我的礼包列表中，玩家可以复制礼包码。在对应的游戏中兑换等价值的奖励。
      </div>
      <div class="vip_level_dec">2：直接通过游戏发放到对应的领取角色中。</div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">VIP等级</div>
          <div class="vip_level_tb">VIP礼包</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">0</div>
          <div class="vip_level_tb">无</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">1-3</div>
          <div class="vip_level_tb">礼包特权 I</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">4-6</div>
          <div class="vip_level_tb">礼包特权 II</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">7-9</div>
          <div class="vip_level_tb">礼包特权 III</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">10</div>
          <div class="vip_level_tb">礼包特权 IV</div>
        </div>
      </div>
      <div class="vip_selects">
        <select id="category" v-model="selectedCategory">
          <option value="">请选择区服</option>
          <option
            v-for="(category, index) in categories"
            :key="index"
            :value="category"
          >
            {{ category }}
          </option>
        </select>
        <select
          id="subcategory"
          v-model="selectedSubcategory"
          :disabled="!selectedCategory"
        >
          <option value="">请选择用户</option>
          <option
            v-for="(subcategory, index) in filteredSubcategories"
            :key="index"
            :value="subcategory"
          >
            {{ subcategory }}
          </option>
        </select>
      </div>
      <div class="get_gift">
        <div class="submit_btn" @click="getGift()">领取礼包</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categories: ["Fruit", "Vegetable"],
      selectedCategory: "",
      subcategories: {
        Fruit: ["Apple", "Banana", "Orange"],
        Vegetable: ["Carrot", "Broccoli", "Tomato"],
      },
      selectedSubcategory: "",
    };
  },
  computed: {
    filteredSubcategories() {
      return this.subcategories[this.selectedCategory] || [];
    },
  },
  methods: {
    getGift() {
      this.$Msg("领取成功");
    },
  },
};
</script>
<style scoped lang="scss">
$vip-color: #c37c00;
.page_box {
  padding: 0 0.22rem 1rem 0.22rem;
}
.vip_level_nav {
  margin: 0.4rem 0 0.1rem 0;
  padding: 0 0.3rem;
  line-height: 0.4rem;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 1rem;
}
.vip_level_img {
  margin: 0.3rem 0;
}
.vip_level_dec {
  width: 100%;
  line-height: 0.4rem;
  font-size: 0.23rem;
}
.vip_level_table {
  margin-top: 0.3rem;
  width: 100%;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: #fde2b0;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  height: 0.9rem;
  color: $vip-color;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 0.6rem;
  font-size: 0.23rem;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.vip_selects {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0.3rem 0;
  select {
    height: 0.5rem;
    width: 2.5rem;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;
  }
  select:focus {
    outline: none;
  }
}
.get_gift {
  width: 100%;
  display: flex;
  justify-content: center;
  .submit_btn {
    margin-top: 0.2rem;
    width: 50%;
    line-height: 0.7rem;
    text-align: center;
    background-color: #c37c00;
    color: #fff;
    border-radius: 1rem;
  }
}
</style>